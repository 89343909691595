.my-navigation{
  background-color:#E8E8E8;
  border-bottom:1px #222222 darkgray;
  

}
.navbar {
  height: 80px;
  display: flex;
  justify-content: start;
  align-items: center;
  color: black;

 
}

.menu-bars {
  margin-left: 2rem;
  font-size: 2rem;
  color: black;
  background-color: #E8E8E8;
  border-radius: 25px;

}



.nav-menu {
  background-color:#E8E8E8;
  width: 300px;
  height: 100%;
  display: flex;
  justify-content: center;
  position: fixed;
  top: 0;
  left: -100%;
  transition: 850ms;
  z-index:1;

 
  
}

.nav-menu.active {
  left: 0;
  transition: 350ms;
}



.menu-circle{
  height: 50px;
  width: 50px;
  color: black;
}

.nav-text {
  display: flex;
  justify-content: start;
  background-color: #E8E8E8;
  align-items: center;
  padding: 8px 0px 8px 16px;
  list-style: none;
  height: 60px;
  color: black;
}

.nav-text a {
  text-decoration: none;
  background-color: #E8E8E8;
  font-size: 18px;
  width: 95%;
  height: 100%;
  display: flex;
  align-items: center;
  padding: 0 16px;
  border-radius: 4px;
  color: black;

}


/* ???? self */
.nav-text :hover{
  text-decoration: none;
  background-color: #E8E8E8;
  font-size: 18px;
  width: 95%;
  height: 100%;
  display: flex;
  align-items: center;
  padding: 0 16px;
  border-radius: 4px;

}

.nav-text a:hover {
  background-color: #E8E8E8;
  text-decoration: none;
  border-bottom: 2px solid ;
  color: black;
}

.nav-text a:active {
  /* background-color: #333333;
  text-decoration: none; */
  border-bottom: 2px solid #f6f6e7;
}



.nav-menu-items {
  width: 100%;
  
}



.navbar-toggle {
  background-color:#E8E8E8;
  width: 100%;
  height: 80px;
  display: flex;
  justify-content: start;
  align-items: center;
}

span {
  margin-left: 16px;
 
}



