

/* ********************** */
/* hero-section styling */
/* ********************** */




.hero-section {
    /* margin:0 10% 0 10% ; */
    padding: 0% 5% 5% 5%;
}

.content-box{
    background-color:#f0f0f0;
    display: flex;   
   

}


.hero-section hr{
    height: 1px;
    background-color: black;
    border: none;
}


.hero-text {
	margin-bottom: 80px;
}

.hero-text h2 {
    font-size: 70px;
    font-weight: bold;
    color: black;
	line-height: normal;
    margin-bottom: 20px;
    padding-top: 100px;
    font-family: 'Josefin Sans', sans-serif;
}

.hero-text p {
	font-size: 30px;
    line-height: 1.3;
    color: black;
    font-family: 'Montserrat', sans-serif;
}


.hero-image {
    padding-top: 20%;
    display: flex;
    justify-content: center; 
   
}

.hero-image img{
    border-radius: 500px;
    height: 80%;
    width: 80%;
    padding: 0 auto;
    z-index: 0;

}

.hero-info h2 {
    font-size: 35px;
    font-weight: bold;
    margin-bottom: 30px;
    font-family: 'Lato', sans-serif;
    color: black;
}

.hero-info ul {
    list-style: none;
    margin: 0; 
    padding: 0;
}

.hero-info ul li {
	font-size: 20px;
	color: #808181;
    margin-bottom: 15px;
    margin: 0;
    padding: 0;
 
  
}

.hero-info ul li span {
	color: black;
    min-width: 150px;
    margin: 0 auto;
    padding: 0 auto;
}

.hero-info ul li:last-child {
	margin-bottom: 0;
}


/* ********************** */
/* social-section styling */
/* ********************** */


.social-section{
    margin-top: 8%;
}


 
.social-section .social-links {
	display: flex;
	background: #f0f0f0;
	/* position: relative; */
    justify-content: center;
    /* padding-left: 10%; */
  

}

.social-section .social-links a {
	color: black;
	font-size: 36px;
    margin-left: 5%;
    margin-right: 5%;
    height: 50px;
    width: 50px;
    align-items: center;
    
}

.social-section .social-link-warp {
    /* position: relative; */
    padding-bottom: 5%;
}


.social-section h2{
    color: black;
}



.social-section hr{
    height: 1px;
    background-color: black;
    border: none;
    width: 80%;
    margin: 0 10%;
    /* margin-left: 2%; */
}



/* ********************** */
/* projects-section styling */
/* ********************** */


.projects-section{
    padding: 0 5%;
    background-color: #f0f0f0
   
}


.projects-section h1{
    color: black;
    font-size: 2.5em;
    font-weight: bold;
    line-height: normal;
    margin-bottom: 20px;
    font-family: 'Josefin Sans', sans-serif;


}


.projects-section hr{
    margin-bottom: 5em;
   


}



.projects-section h2 {
    font-size: 22px;
    font-weight: bold;
    color: white;
	line-height: normal; 
    padding-top: 0;

    font-family: 'Josefin Sans', sans-serif;
 
}


.projects-section p{
    line-height: 1.5;
    color: white;
    font-family: 'Montserrat', sans-serif;
    padding: 0;
  
}




.projects-header h2{
    padding-top: 2em;
    padding-bottom: 2em;;
    text-align: center;
    font-size: 2em;
    font-weight: bold;
	line-height: normal;
    font-family: 'Josefin Sans', sans-serif; 
}


.project-content{
   margin: 0;
   padding: 0;
   background-color: #f0f0f0;
   
      
  }


  .repo-button{
      margin-top:2em;
      background-color:whitesmoke;
  }


  .repo-button a{
      text-decoration: none;
      color: black;
    
  }

 

  .repo-button a:hover{
    text-decoration: none;
    color: black;

}
  

 





/**
Footer Section
*/


.footer-section{
    height: 60px;
    padding-top: 15px;
    margin-top: 10px ;
    margin-bottom: 10px;
    margin: 0 ;
    color: black;
    background-color:#E8E8E8;
    padding: 10px;
    box-shadow: 5px 10px #222222;
}

/* *************** */
/* contact-section styling */
/* *************** */

.contact-section{
    padding:0 5% ;
    background-color:#f0f0f0;
}

.contact-box{
    background-color:#f0f0f0;
}

.contact-box Label{
    font-weight: 400;
    font-size: 17px;
}


.contact-header{
    margin-top: 4em;
    margin-bottom: 3em;
    color: black;
    font-weight: bold;



}

.contact-header h2{
    font-size: 2em;
    font-family: 'Josefin Sans', sans-serif;
    color: black;
    font-weight: bold;
    text-align: center;
}


.contact-form-class{
    color: black;
    padding-bottom: 40%;
    
}


/* *************** */
/* about-section styling */
/* *************** */



.about-section{
    padding: 0% 10% 0% 10%;
    margin-bottom: 0%;
    color: black;
}


.about-section hr{
    height: 1px;
    background-color: #ccc;
    border: none;
}

.about-header h2{
    text-align: center;
    font-size: 2em;
    font-weight: bold;
	line-height: normal;
    font-family: 'Josefin Sans', sans-serif; 
}


.about-image{
    display: flex;
    align-items: center;
    align-items: center;
    justify-content: center;
}
.about-image img{
    height: 40%;
    width: 40%;
}

.about-me{
    margin-top: 40px;
    margin-bottom: 5em;
}

.about-me p{
    margin-top: 2em;
    line-height:1.5em;
    font-size: 20px;
}


/* Education Section */

.edu-section {
    /* margin:0 10% 0 10% ;  */
    padding: 0% 5% 0% 5%;
    background: linear-gradient(to bottom,#FaFaFa, #F5F5F5,#F5F5F5, #F8F8F8,#FaFaFa, white);
 
}


.edu-section .edu-box{
    display: flex;   
    justify-content: center;
    align-items: center;
}


.edu-section hr {
    height: 1px;
    background-color: black;
    border: none;
}


.edu-header {
    display: flex;
    justify-content: center;
    padding-top: 3em;
    padding-bottom: 2em;
}


.edu-quote {
    display: flex;
    justify-content: center;
    padding-top: 7em;
    padding-bottom: 5em;
}


.edu-header h2{
    text-align: center;
    font-size: 2em;
    font-weight: bold;
	line-height: normal;
    font-family: 'Josefin Sans', sans-serif; 
}


.edu-box {
    display: flex;
    justify-content: center;
}


.education-section .card-container{
    display: flex;
    justify-content: center;


    
}


.profile-card {
    width: 80%;
    max-width: 800px;
    background-color: #FFF;
    border-radius: 5px;
    box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    position: relative;
    margin: 20px 50px;
    cursor: pointer;
}


.img-responsive{
    height: 100%;
    width: 100%;
}


.profile-card img {
    transition: all 0.25s linear;
}

.profile-card .profile-content {
    position: relative;
    padding: 15px;
    background-color: #FFF;
}

.profile-card .profile-name {
    font-weight: bold;
    position: absolute;
    left: 0px;
    right: 0px;
    top: -60px;
    color: #FFF;
    font-size: 17px;
}

.profile-card .profile-name p {
    font-weight: 600;
    font-size: 13px;
    letter-spacing: 1.5px;
}

.profile-card .profile-description {
    color: #777;
    font-size: 12px;
    padding: 10px;
}

.profile-card .profile-overview {
    padding: 15px 0px;
}

.profile-card .profile-overview p {
    font-size: 10px;
    font-weight: 600;
    color: #777;
}

.profile-card .profile-overview h4 {
    color: #273751;
    font-weight: bold;
}

.profile-card .profile-content::before {
    content: "";
    position: absolute;
    height: 20px;
    top: -10px;
    left: 0px;
    right: 0px;
    background-color: #FFF;
    z-index: 0;
    transform: skewY(3deg);
}

.profile-card:hover img {
    transform: rotate(5deg) scale(1.1, 1.1);
    filter
    : brightness(110%);
}




/* CUSTOM LINE COLOR */
/* The line */
.vertical-timeline.vertical-timeline-custom-line::before {
    background:#424242;
}


.vertical-timeline.vertical-timeline-custom-line .vertical-timeline-element--work .vertical-timeline-element-icon {
    box-shadow: 0 0 0 4px #424242, inset 0 2px 0 rgba(0, 0, 0, 0.08), 0 3px 0 4px rgba(0, 0, 0, 0.05);
  }
  
  .vertical-timeline.vertical-timeline-custom-line .vertical-timeline-element--education .vertical-timeline-element-icon {
    box-shadow: 0 0 0 4px #424242, inset 0 2px 0 rgba(0, 0, 0, 0.08), 0 3px 0 4px rgba(0, 0, 0, 0.05);
  }
      
    