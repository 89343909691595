.my-component{
    /* background-color: #f0f0f0; */
    overflow: hidden;
}

@media (max-width: 480px) {
    .content{
       margin-left: 20px; 
    }
}

@media (max-width: 320px) {
    .content{
       margin-left: 10px;
    }
}





@media (min-width:320px)  { /* smartphones, portrait iPhone, portrait 480x320 phones (Android) */ }
@media (min-width:480px)  { /* smartphones, Android phones, landscape iPhone */ }
@media (min-width:600px)  { /* portrait tablets, portrait iPad, e-readers (Nook/Kindle), landscape 800x480 phones (Android) */ }
@media (min-width:801px)  { /* tablet, landscape iPad, lo-res laptops ands desktops */ }
@media (min-width:1025px) { /* big landscape tablets, laptops, and desktops */ }
@media (min-width:1281px) { /* hi-res laptops and desktops */ }